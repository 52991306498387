// ********************************************************
// IMPORT
// ********************************************************
import 'dotenv'
import 'url-search-params-polyfill'

// import { registerSW } from 'virtual:pwa-register'
// const updateSW = registerSW({
//   onOfflineReady() { },
// })

import { useRegisterSW } from 'virtual:pwa-register/vue'
const intervalMS = 60 * 60 * 1000
const updateServiceWorker = useRegisterSW({
  onRegistered(r) {
    r && setInterval(() => {
      r.update()
    }, intervalMS)
  }
})

const urlParams = new window.URLSearchParams(window.location.search)


// ********************************************************
// VCONSOLE (frontend console on mobile)
// ********************************************************
let vconsole = false
if (urlParams.get('vconsole') !== null) vconsole = true
if (urlParams.get('vconsole') === 'false') vconsole = false
const isMiniProgram = window.__wxjs_environment === 'miniprogram'
if (import.meta.env.VITE_APP_API_ENV == 'staging' && isMiniProgram) vconsole = true


// ********************************************************
// INIT
// ********************************************************
const init = async function () {
  if (vconsole) {
    let module = await import('vconsole')
    let VConsole = module.default
    const vConsole = new VConsole()
  }
  console.log(import.meta.env.PACKAGE_VERSION)
  console.log(window.location.href)
  await import('./vue')

}
init()